'use strict';
module.exports = videoCarousel;

function videoCarousel() {
  
  console.log('video carousel init');
  const myCarousel = document.getElementById('toddlersknowvideos')

  const isMobile = (window.innerWidth <= 1024) ? true : false;
  let stopCarousel = false;  

  myCarousel.addEventListener('slide.bs.carousel', event => {
      if (event.to === 2) {
        clearInterval(videoSlideInterval);
      }

      if (event.to === 0) {
        document.querySelector('#toddlersknow .carousel-control-prev-icon').classList.add('disabled');
      } else if (event.to === 2) {
        document.querySelector('#toddlersknow .carousel-control-next-icon').classList.add('disabled');
      } else {
        document.querySelector('#toddlersknow .carousel-control-next-icon').classList.remove('disabled');
        document.querySelector('#toddlersknow .carousel-control-prev-icon').classList.remove('disabled');
      }
  });

  const videoSlideInterval = setInterval(() => {
    $("#toddlersknowvideos").carousel('next');
  }, 1000);

  document.querySelectorAll('.vjs-big-play-button').forEach(button => {
    button.addEventListener('click', () => {
      clearInterval(videoSlideInterval);
    });
  });
}