'use strict';
module.exports = Utility;

function Utility() {
    var animate = function(selector, time, styles) {
    setTimeout(function() {
        if (styles) {
        selector.css(styles);
        } else {
        selector.css('opacity', 1);
        }
    }, time);
    };
    return {
    animate: animate
    };
}