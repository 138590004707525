'use strict';
module.exports = overviewInit;

function overviewInit() {
    var animate = require('./utility')().animate;
    animate($('.hero .fade-in-0'), 1200);
    animate($('.hero .fade-in-1'), 1300);
    animate($('.hero .fade-in-2'), 1400);
    animate($('.hero .fade-in-3'), 1500);
    animate($('.hero .fade-in-4'), 1600);
    animate($('.hero .fade-in-5'), 1700);
    animate($('.hero .fade-in-6'), 1800);
}