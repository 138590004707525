'use strict';

var $ = jQuery;
var header = require('./header.js');
var overview = require('./overview.js');
var simplify = require('./simplify.js');
var footer = require('./footer.js');
var video = require('./video.js');

jQuery(whenReady);

function whenReady() {
    header();
    overview();
    simplify();
    footer();
    video();

    var width = $(window).outerWidth();

    $(window).on('resize', function() {
    if ($(window).outerWidth() != width) {
        if ($(window).outerWidth() <= 1024) {
            location.reload();
        }
    }
    });

    setTimeout(function() {
    if (window.location.href.indexOf('reconstitution-video') > -1) {
        $('#video1')[0].currentTime = 0;
        $('#video-overlay').show();
        //$('#video1')[0].play();
    }
    }, 500);

    //modals
    const modals = document.getElementsByTagName("dialog");
    const openModal = function() {
        const modal = document.getElementById("dialog"+this.id.match(/\d+/)[0]);
        modal.showModal();
    };
    const closeModal = function() {
        document.getElementById("dialog"+this.parentNode.id.match(/\d+/)[0]).close();
        //close modal on video end
        
        //stop video on modal close
        const toddlerVideo0 = new Vimeo.Player(document.getElementById('toddler-video-0'));
        const toddlerVideo1 = new Vimeo.Player(document.getElementById('toddler-video-1'));
        const toddlerVideo2 = new Vimeo.Player(document.getElementById('toddler-video-2'));
        [toddlerVideo0, toddlerVideo1, toddlerVideo2].forEach(v => {
            v.pause();
        });
    };
    Array.from(modals).forEach(function(modal) {
        //open modal on preview pane click
        document.getElementById("ep" + modal.id.match(/\d+/)[0]).addEventListener("click", openModal);
        //close modal on close button
        modal.getElementsByTagName("img")[0].addEventListener("click", closeModal);
    });
}