'use strict';
module.exports = headerInit;

function headerInit() {
    var animate = require('./utility')().animate;
    var navTabs = $('.nav_item');
    var currentPos = 0;
    var contentHeight = $(window).width() > 1023 ? 6534 : 7097;

    //takes 1.8 s to transition completely
    $('.header__desktop .fade-in-0').css('opacity', 1);

    //menu from right to left
    animate($('.header__desktop .fade-in-1'), 200);
    animate($('.header__desktop .fade-in-2'), 400);
    animate($('.header__desktop .fade-in-3'), 600);
    animate($('.header__desktop .fade-in-4'), 800);
    animate($('.header__desktop .fade-in-5'), 800, {
    opacity: 1,
    right: 0
    });

    checkActiveTab(0);

    $('.nav_item').click(function(e) {
    e.preventDefault();
    var sectionId = $(this).attr('href');
    scroll_to(sectionId);
    });

    // $( window ).resize( function () {
    //   currentPos = 0;
    // });

    function checkActiveTab(position) {
    if (position < 1350 && currentPos !== 1) {
        navTabs.removeClass('active');
        navTabs.eq(0).addClass('active');
        currentPos = 1;
    } else if (
        position >= 1350 &&
        position < 2180 &&
        currentPos !== 2
    ) {
        navTabs.removeClass('active');
        navTabs.eq(1).addClass('active');
        currentPos = 2;
    } else if (
        position >= 2180 &&
        position < 3175 &&
        currentPos !== 3
    ) {
        navTabs.removeClass('active');
        navTabs.eq(2).addClass('active');
        currentPos = 3;
    } else if (position >= 3175 && position < 6666 && position < 6666 && currentPos !== 4) {
        navTabs.removeClass('active');
        navTabs.eq(3).addClass('active');
        currentPos = 4;
    } else if (position >= 6666 && currentPos !== 5) {
        navTabs.removeClass('active');
        navTabs.eq(4).addClass('active');
        currentPos = 5;
    }
    }

    function scroll_to(sectionId) {
        let headerHeight = document.querySelector('header').offsetHeight;
        let scrollTopValue;
        let scrollTopPadding;
        if (sectionId === "#isiTop") {
            // $(window).width() < 768 ? scrollTopPadding = 0 : scrollTopPadding = 100;
            scrollTopPadding = 100;
        }
        else {
            $(window).width() < 1024 ? scrollTopPadding = 60 : scrollTopPadding = 60;
        }
        scrollTopValue = document.querySelector(sectionId).offsetTop - scrollTopPadding + 60;
        animateScroll(scrollTopValue);
    }

    function animateScroll(offsetTop) {
    $('html, body').animate(
        {
        scrollTop: offsetTop
        },
        1900
    );
    }

    ///mobile
    $('.menu__selector').click(function(e) {
    var $this = $(this);
    $this.parent().addClass('extend');
    });

    $('.menu__selector__open').click(function(e) {
    var $this = $(this);
    $this.parent().removeClass('extend');
    });

    $('.menu__mobile nav a').click(function(e) {
    e.preventDefault();
    var $this = $(this),
        $data = $this.data('name');

    //$('.menu__selector').text($data);
    $('.menu__mobile').removeClass('extend');
    $('.menu__mobile nav a').removeClass('active');
    $this.addClass('active');

    // $( 'html, body' ).animate( {
    //   scrollTop: $( $link ).offset().top - 110
    // }, 2000 );
    });

    var shrinkHeader = document.getElementById('height-0-on-scroll').clientHeight;
    let heroOffsetTop = document.getElementById('hero').offsetTop + 75;
    if ($(window).outerWidth() <= 1023) {
    $(window).scroll(function() {
        var scroll = getCurrentScroll();
        if (scroll >= shrinkHeader) {
        // $('.header__mobile').addClass('fixed');
        // $('#hero').css('margin-top', heroOffsetTop);
        } else {
        // $('.header__mobile').removeClass('fixed');
        // $('#hero').css('margin-top', 0);
        }
        var position = $(this).scrollTop();
        checkActiveTabMob($(this).scrollTop());
    });
    } else {
    $(window).scroll(function() {
        var position = $(this).scrollTop();
        checkActiveTab(position);
    });
    }

    function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
    }

    function checkActiveTabMob(position) {
    var $data;
    if (position < 1261 && currentPos !== 1) {
        $data = $('.menu__mobile nav a')
        .eq(0)
        .data('name');
        //$('.menu__selector').text($data);
        $('.menu__mobile nav a').removeClass('active');
        $('.menu__mobile nav a')
        .eq(0)
        .addClass('active');
        currentPos = 1;
    } else if (
        position >= 1261 &&
        position < 1933 &&
        currentPos !== 2
    ) {
        $data = $('.menu__mobile nav a')
        .eq(1)
        .data('name');
        //$('.menu__selector').text($data);
        $('.menu__mobile nav a').removeClass('active');
        $('.menu__mobile nav a')
        .eq(1)
        .addClass('active');
        currentPos = 2;
    } else if (
        position >= 1933 &&
        position < 3240 &&
        currentPos !== 3
    ) {
        $data = $('.menu__mobile nav a')
        .eq(2)
        .data('name');
        //$('.menu__selector').text($data);
        $('.menu__mobile nav a').removeClass('active');
        $('.menu__mobile nav a')
        .eq(2)
        .addClass('active');
        currentPos = 3;
    } else if (position >= 3240 && currentPos !== 4) {
        $data = $('.menu__mobile nav a')
        .eq(3)
        .data('name');
        //$('.menu__selector').text($data);
        $('.menu__mobile nav a').removeClass('active');
        $('.menu__mobile nav a')
        .eq(3)
        .addClass('active');
        currentPos = 4;
    }
    }
}