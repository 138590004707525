'use strict';
module.exports = simplify;

function simplify() {
    let chartTab = $('.chart__tab');
    setTabsHeight();
    $(window).resize(setTabsHeight);

    $('.simplify .down-arrow')
    .eq(0)
    .css('opacity', 1);

    // $('.chart__table.savings').css('opacity', 0);
    // $('.chart__table.without').css('opacity', 0);

    $('.chart__table.savings').css('display', 'none');
    $('.chart__table.without').css('display', 'none');

    $('#bullet--yellow').css('display', 'none');

    chartTab.click(function() {
        chartTab.removeClass('chart__tab--active');
        chartTab.find('.down-arrow').css('opacity', 0);
        $(this).addClass('chart__tab--active');
        $(this)
            .find('.down-arrow')
            .css('display', 1);
        let tabHeight = $(this).outerHeight();
        tabHeight += 6;
        $(this).outerHeight(tabHeight);
        setTabsHeight();
    });

    $('#with').click(function() {
    // $('.chart__table.without').css('opacity', 0);
    // $('.chart__table.savings').css('opacity', 0);
    // $('.chart__table.with').css('opacity', 1);
    $('.chart__table.without').css('display', 'none');
    $('.chart__table.savings').css('display', 'none');
    $('.chart__table.with').css('display', 'table');
    $('#bullet--yellow').css('display', 'none');
    });

    $('#savings').click(function() {
    // $('.chart__table.without').css('opacity', 0);
    // $('.chart__table.savings').css('opacity', 1);
    // $('.chart__table.with').css('opacity', 0);
    $('.chart__table.without').css('display', 'none');
    $('.chart__table.savings').css('display', 'table');
    $('.chart__table.with').css('display', 'none');
    $('#bullet--yellow').css('display', 'none');
    });

    $('#without').click(function() {
    // $('.chart__table.without').css('opacity', 1);
    // $('.chart__table.savings').css('opacity', 0);
    // $('.chart__table.with').css('opacity', 0);
    $('.chart__table.without').css('display', 'table');
    $('.chart__table.savings').css('display', 'none');
    $('.chart__table.with').css('display', 'none');
    $('#bullet--yellow').css('display', 'block');
    });

    $('.chart__col-right, .swipe').on('click touchstart tap', function() {
    $('.swipe').hide();
    });

    function setTabsHeight() {
        setTimeout(function () {

            chartTab.each(function (index) {
                let firstTabHeight;
                if ($(this).attr('id') === "with") {
                    firstTabHeight = $(this).outerHeight();
                }
                // set first tab height as the standard height for all tabs
                $(this).parent().outerHeight(firstTabHeight);
                let elementHeight = $(this).parent().outerHeight();

                if (!$(this).hasClass('chart__tab--active')) {
                    $(this).outerHeight(elementHeight);
                }
            });

        }, 0);
    }
}