'use strict';
module.exports = sticky;

console.log("footer.js");

// on scroll, get the how much ofset the window is to element with id #isiTop
$(window).on('scroll', function() {
    // var offset = $('#isiTop').offset().top - $(window).scrollTop();
    // console.log("scrollY: ", window.scrollY);
    // console.log("window scrollTop: ", $(window).scrollTop());
    
    // console.log('Offset from #isiTop:', offset);
});




function sticky() {
    $(document).scroll(function() {
    var alfa =
        $('#isiTop').offset().top +
        $('.isi-sticky').outerHeight() -
        $(window).outerHeight();
    var y = $(this).scrollTop();
    if (y > alfa) {
        $('.isi-sticky').addClass('hide');
    } else {
        $('.isi-sticky').removeClass('hide');
    }
    });

    // $(".isi-sticky .plus-icon").click(function() {
    // 	var $this = $(this)
    //     $('html, body').animate({
    //         scrollTop: $("#isiTop").offset().top + 160
    //     }, 2000);
    // });

    function collapseIcon() {
    var $iconSticky = $('.isi-sticky .plus-icon');
    $iconSticky.addClass('collapsed');

    setTimeout(function() {
        $iconSticky.removeClass('collapsed');
    }, 2000);
    }

    $(
    '.isi__header, .isi-sticky .plus-icon, .isi-sticky .isi-top__title'
    ).click(function(e) {
        e.preventDefault();
        document.getElementById('isiTop').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
        collapseIcon();


    // if ($(window).outerWidth() <= 1023) {
    //     console.log("1");
    //     $('html, body').animate(
    //     {
    //         scrollTop: $('#isiTop').offset().top - 100
    //     },
    //     2000
    //     );
    //     collapseIcon();
    // } else {
    //     if ($('.header__desktop').hasClass('skrollable-after')) {
    //         console.log("2");
    //     $('html, body').animate(
    //         {
    //         scrollTop: $('#isiTop').offset().top - 150
    //         },
    //         2000
    //     );
    //     collapseIcon();
    //     } else if ($('.header__desktop').hasClass('one-time')) {
    //         console.log("3");
    //     $('html, body').animate(
    //         {
    //         scrollTop: $('#isiTop').offset().top - 220
    //         },
    //         2000
    //     );
    //     $('.header__desktop').removeClass('one-time');
    //     collapseIcon();
    //     } else {
    //         console.log("4");
    //     $('html, body').animate(
    //         {
    //         scrollTop: $('#isiTop').offset().top - 250
    //         },
    //         2000
    //     );
    //     collapseIcon();
    //     }
    // }
    });
}